"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PulveIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PulveIcon = function PulveIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M5.7 29.5l1.8-3.1 1.7 3.1M13.7 29.5l1.8-3.1 1.8 3.1M21.7 29.5l1.8-3.1 1.8 3.1M29.8 29.5l1.7-3.1 1.8 3.1"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M15.5 20.8L3.8 23.4c-.6.1-1 .8-.7 1.4l.6 1.1c.2.3.5.5.8.5h29.8c.3 0 .7-.2.8-.5l.6-1.1c.3-.6 0-1.3-.7-1.4l-11.7-2.6"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M22.7 23.2h-6.4c-.3 0-.6-.3-.6-.6l.6-12.5c0-.3.3-.6.6-.6H22c.3 0 .6.3.6.6l.6 12.5c.1.4-.2.6-.5.6z"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M16.2 14.3h6.6"
  }));
};

exports.PulveIcon = PulveIcon;