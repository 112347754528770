"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HayMakingIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var HayMakingIcon = function HayMakingIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("ellipse", {
    fill: "transparent",
    stroke: props.color,
    cx: 27.9,
    cy: 23,
    rx: 2.6,
    ry: 1.4
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M20.2 21.7c2-.6 4.3-.4 6 .2M20.8 25.1c.7-1.2 2.5-2 4.5-2.2M25.5 27.3c-1-1.1-.8-2.4.4-3.3M31.6 27c-2-.4-3.5-1.3-3.9-2.4M35.5 24.4c-2 .6-4.3.4-6-.2M35 21c-.7 1.2-2.5 2-4.6 2.2M30.3 18.8c1 1.1.8 2.4-.4 3.3M24.1 19.1c2.1.4 3.6 1.4 3.9 2.5"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M33.4 11.7l-5.5 4v5.8"
  }), _react["default"].createElement("ellipse", {
    fill: "transparent",
    stroke: props.color,
    cx: 9.5,
    cy: 23,
    rx: 2.6,
    ry: 1.4
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M1.9 21.7c2-.6 4.3-.4 6 .2M2.4 25.1c.7-1.2 2.5-2 4.5-2.2M7.2 27.3c-1-1.1-.8-2.4.4-3.3M13.3 27c-2.1-.4-3.5-1.3-3.9-2.4M17.2 24.4c-2 .6-4.3.4-6-.2M16.7 21c-.7 1.2-2.5 2-4.6 2.2M11.9 18.8c1 1.1.8 2.4-.4 3.3M5.8 19.1c2.1.4 3.6 1.4 3.9 2.5"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M15 11.7l-5.5 4v5.8"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M37.1 11.7H6.2"
  }));
};

exports.HayMakingIcon = HayMakingIcon;