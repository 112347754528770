"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icons = void 0;

var _addCircleIcon = require("./addCircleIcon");

var _arrowBackIcon = require("./arrowBackIcon");

var _chevronDownIcon = require("./chevronDownIcon");

var _chipperIcon = require("./chipperIcon");

var _closeIcon = require("./closeIcon");

var _deleteIcon = require("./deleteIcon");

var _fieldRollerIcon = require("./fieldRollerIcon");

var _harvestIcon = require("./harvestIcon");

var _hayMakingIcon = require("./hayMakingIcon");

var _locationIcon = require("./locationIcon");

var _logoutIcon = require("./logoutIcon");

var _minusIcon = require("./minusIcon");

var _miscellaneous = require("./miscellaneous");

var _moreVertIcon = require("./moreVertIcon");

var _parcelIcon = require("./parcelIcon");

var _penIcon = require("./penIcon");

var _personIcon = require("./personIcon");

var _plowIcon = require("./plowIcon");

var _plusIcon = require("./plusIcon");

var _pulveIcon = require("./pulveIcon");

var _seaderIcon = require("./seaderIcon");

var _solIcon = require("./solIcon");

var _spreaderIcon = require("./spreaderIcon");

var _tractorIcon = require("./tractorIcon");

var _trailerIcon = require("./trailerIcon");

var _checkIcon = require("./checkIcon");

var Icons = {
  AddCircleIcon: _addCircleIcon.AddCircleIcon,
  ChevronDownIcon: _chevronDownIcon.ChevronDownIcon,
  ChipperIcon: _chipperIcon.ChipperIcon,
  DeleteIcon: _deleteIcon.DeleteIcon,
  FieldRollerIcon: _fieldRollerIcon.FieldRollerIcon,
  HarvestIcon: _harvestIcon.HarvestIcon,
  HayMakingIcon: _hayMakingIcon.HayMakingIcon,
  MinusIcon: _minusIcon.MinusIcon,
  MiscellaneousIcon: _miscellaneous.MiscellaneousIcon,
  ParcelIcon: _parcelIcon.ParcelIcon,
  PersonIcon: _personIcon.PersonIcon,
  PlowIcon: _plowIcon.PlowIcon,
  PlusIcon: _plusIcon.PlusIcon,
  PulveIcon: _pulveIcon.PulveIcon,
  SeederIcon: _seaderIcon.SeederIcon,
  SolIcon: _solIcon.SolIcon,
  SpreaderIcon: _spreaderIcon.SpreaderIcon,
  TractorIcon: _tractorIcon.TractorIcon,
  TrailerIcon: _trailerIcon.TrailerIcon,
  LogoutIcon: _logoutIcon.LogoutIcon,
  ArrowBackIcon: _arrowBackIcon.ArrowBackIcon,
  PenIcon: _penIcon.PenIcon,
  MoreVertIcon: _moreVertIcon.MoreVertIcon,
  CloseIcon: _closeIcon.CloseIcon,
  LocationIcon: _locationIcon.LocationIcon,
  CheckIcon: _checkIcon.CheckIcon
};
exports.Icons = Icons;