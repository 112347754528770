"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TractorIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var TractorIcon = function TractorIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M7.1 23.7c0 3.4 2.7 6.2 6.1 6.2 2.6 0 4.9-1.6 5.8-4M19 25.9c.3-.7.4-1.4.4-2.2 0-3.4-2.7-6.2-6.1-6.2S7 20.2 7 23.6"
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 13.2,
    cy: 23.7,
    r: 2.5
  }), _react["default"].createElement("circle", {
    cx: 27.9,
    cy: 25.9,
    r: 0.6,
    fill: props.color,
    stroke: "transparent"
  }), _react["default"].createElement("circle", {
    fill: "transparent",
    stroke: props.color,
    cx: 27.9,
    cy: 25.9,
    r: 4
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M9.6 11.8h10.6M31.8 19h-7.9M22.2 16.1l-5.7 2M22.2 16.1l-1.8-4.3 1.7-.9-.4-1.8H10.1c-.4 0-.7.3-.7.6l.1 8.9M7.1 23.7c0 3.4 2.7 6.2 6.1 6.2 2.6 0 4.9-1.6 5.8-4h4.9c0 2.2 1.8 4 4 4s4-1.8 4-4l.1-8.1c0-1-.8-1.7-1.7-1.7h-8"
  }));
};

exports.TractorIcon = TractorIcon;