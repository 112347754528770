"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FieldRollerIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var FieldRollerIcon = function FieldRollerIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M6.5 29.2c-.4 0-.7-.3-.7-.7v-3.6c0-.4.3-.7.7-.7h7.9c.4 0 .7.3.7.7v3.6c0 .4-.3.7-.7.7H6.5zM24.7 29.2c-.4 0-.7-.3-.7-.7v-3.6c0-.4.3-.7.7-.7h7.9c.4 0 .7.3.7.7v3.6c0 .4-.3.7-.7.7h-7.9z"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M5.6 26.4h-2c-.2 0-.4-.2-.4-.4v-3c0-.9.7-1.7 1.7-1.7H16c.9 0 1.7.7 1.7 1.7v3c0 .2-.2.4-.4.4h-2M23.8 26.4h-2.1c-.2 0-.4-.2-.4-.4v-3c0-.9.7-1.7 1.7-1.7h11.1c.9 0 1.7.7 1.7 1.7v3c0 .2-.2.4-.4.4h-2"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M10.6 21.4l6.1-5h5.8l6.1 4.9M22.5 16.4h-5.8l2.2-6.6h1.4z"
  }));
};

exports.FieldRollerIcon = FieldRollerIcon;