"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChipperIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ChipperIcon = function ChipperIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("circle", {
    fill: 'transparent',
    stroke: props.color,
    cx: 19.8,
    cy: 8.9,
    r: 2.1
  }), _react["default"].createElement("path", {
    fill: 'transparent',
    stroke: props.color,
    d: "M27.8 20.9c-.4-.5-1-.9-1.7-.9-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1h.3M25.1 20.3l-3.9-9.7M18.5 10.6L17 17.3l-5.5 3M14.2 24.8V23c0-1.5-1.2-2.7-2.7-2.7-1.5 0-2.7 1.2-2.7 2.7v1.8M3.8 29h15.1l-1-4.1H4.8zM18.9 29v3.2H3.8V29M35.2 32.2H23.1L29 18.1h6.2z"
  }));
};

exports.ChipperIcon = ChipperIcon;