"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HarvestIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var HarvestIcon = function HarvestIcon(props) {
  return _react["default"].createElement("svg", {
    fill: props.color,
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("path", {
    fill: 'transparent',
    stroke: props.color,
    d: "M31.3 23.2h2c.1 0 .2-.1.2-.2v-2c0-.1-.1-.2-.2-.2h-.9l.7-1.9-1.8-3.6h-5.2l-1.7-4.7M19 25.6h5.3M10.6 19.9l-7.7 4.3c-.6.4-.3 1.4.4 1.4h5.5"
  }), _react["default"].createElement("ellipse", {
    fill: 'transparent',
    stroke: props.color,
    cx: 13.9,
    cy: 24.1,
    rx: 5.3,
    ry: 5.4
  }), _react["default"].createElement("circle", {
    fill: 'transparent',
    stroke: props.color,
    cx: 13.9,
    cy: 24.1,
    r: 2.4
  }), _react["default"].createElement("ellipse", {
    fill: 'transparent',
    stroke: props.color,
    cx: 28.1,
    cy: 25.6,
    rx: 3.9,
    ry: 4
  }), _react["default"].createElement("circle", {
    cx: 28.1,
    cy: 25.6,
    r: 0.6,
    fill: props.color,
    stroke: 'transparent'
  }), _react["default"].createElement("path", {
    fill: 'transparent',
    stroke: props.color,
    d: "M15 10.9v7.8M31.2 15.3l.7-2.2 1.2-.9-.8-1.6H12.9c0-.7-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2H7.4l3.2 9.3M33.6 21.8h2.9"
  }));
};

exports.HarvestIcon = HarvestIcon;