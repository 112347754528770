"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrailerIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var TrailerIcon = function TrailerIcon(props) {
  return _react["default"].createElement("svg", {
    height: "".concat(props.size || 39),
    width: "".concat(props.size || 39),
    viewBox: '0 0 39 39'
  }, _react["default"].createElement("circle", {
    fill: props.color,
    stroke: "transparent",
    cx: 9.1,
    cy: 26.2,
    r: 0.6
  }), _react["default"].createElement("circle", {
    fill: props.color,
    stroke: "transparent",
    cx: 19.2,
    cy: 26.2,
    r: 0.6
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M32.1 15.7H3"
  }), _react["default"].createElement("ellipse", {
    fill: "transparent",
    stroke: props.color,
    cx: 9.1,
    cy: 26.4,
    rx: 3.2,
    ry: 3.3
  }), _react["default"].createElement("ellipse", {
    fill: "transparent",
    stroke: props.color,
    cx: 19.3,
    cy: 26.4,
    rx: 3.2,
    ry: 3.3
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M31.5 11H9.4c-.3-.7-.7-1.6-1.5-1.6H3.7c-.5 0-.9.4-.9.9V20c0 .5.4.9.9.9h27.8c.5 0 .9-.4.9-.9v-8.2c-.1-.5-.4-.8-.9-.8zM7.1 23.7H5.4l-1-2.6M17.2 23.7H11M30.7 21.4l-.9 2.3h-8.4"
  }), _react["default"].createElement("path", {
    fill: "transparent",
    stroke: props.color,
    d: "M30.8 20.8l5.4 2.9h-6.4"
  }));
};

exports.TrailerIcon = TrailerIcon;