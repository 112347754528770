import React, { useCallback, useState } from 'react';
import { Button } from 'components';
import { connect } from 'react-redux';
import Expandable from '../modal/Expandable';
import styled from 'styled-components';
import { color, size, spacing } from 'sdk';
import ClustersActioner from '../../services/clusters/ClustersActioner';
import UsersActioner from '../../services/users/UsersActioner';

function AddDriver({ addDriverExpanded, linkUserToClusterByMail, dispatch, clusterID, setAddDriverExpanded }) {
  const close = useCallback(() => setAddDriverExpanded(false), [setAddDriverExpanded]);
  const [driverName, setDriverName] = useState('');
  console.log(clusterID);
  return (
    <Expandable expanded={addDriverExpanded} onRequestClose={close} title={'Ajouter un chauffeur'}>
      <AddDriverInputLabel htmlFor="name_of_driver">Nom du chauffeur</AddDriverInputLabel>
      <TextInput name="name_of_driver" onChange={e => setDriverName(e.target.value)} value={driverName} />
      <Button
        backgroundColor={'bleen'}
        onClick={() => {
          linkUserToClusterByMail({ user: { mail: driverName }, clusterID }).then(user => {
            dispatch({ type: 'assigned_to_id', payload: user.id });
            close();
          });
        }}
      >
        Créer le nouveau chauffeur
      </Button>
    </Expandable>
  );
}

const mapDispatchToProps = dispatch => ({
  createCluster: payload => dispatch(ClustersActioner.createCluster(payload)),
  linkUserToClusterByMail: payload => dispatch(UsersActioner.linkUserToClusterByMail(payload)),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddDriver);

const AddDriverInputLabel = styled.label`
  font-size: ${size('xs')};
  margin: ${spacing / 2}px ${spacing / 2}px ${spacing / 2}px 0;
  color: ${color('bleen')};
`;

const TextInput = styled.input`
  font-size: ${size('m')};
  width: 100%;
  color: ${color('grey')};
  outline: none;
  margin-top: 2px;
  margin-bottom: ${spacing / 2}px;
  border: 1px solid ${color('bleen', 'light')};
  border-radius: 4px;
  padding: ${spacing / 2}px ${spacing / 4}px;
`;
